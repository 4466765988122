.project {
    background-color: var(--color-bg);
    /* display: flex;
    justify-content: center;
    flex-direction: column; */
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}



.backToHome.MuiButton-root {
    color: black;
    padding: 0.3rem;
    margin: 0.5rem;
    font-style: italic;
    background-color: rgba(0,0,0,0.1);
}


.project__container {
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 3rem;
}

.project__image {
    display: flex;
    justify-content: center;
}

.project__image img {
    aspect-ratio: 1/1;
    height: 35rem;
    width: 35rem;
    border-radius: 1.5rem;
}

.info {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.info {
    width: 100%;
}

.card {
    border: 1px solid var(--color-primary-variant);
    border-radius: 1rem;
    padding: 2rem;
    text-align: left;
    margin: 1rem;
}

.card h3 {
    font-size: 1.5rem;
}

.card p, li {
    font-size: 0.95rem;
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
    .info {
        grid-template-columns: 1fr;
    }
    .project__title {
        text-align: center;
    }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600px) {
    .info {
        display: flex;
        flex-direction: column;
        align-items:center;
    }

    .card {
        margin: 0.2rem;
        padding: 1rem;
        width: 90%;
        justify-content: center;
    }

    .project__image img {
        height: 25rem;
        width: 25rem;
        padding: 1.5rem;
        border-radius: 3rem;
    }

    .title h1 {
        font-size: 175%;
    }

    .project__title {
        text-align: center;
    }
}
