#contact {
    background: var(--color-bg-3);
}
.container.contact__container {
    width: 58%;
    display: grid;
    grid-template-columns: 30% 58%;
    gap: 12%;
}

.contact__options {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.contact__option {
    padding: 1.2rem;
    border-radius: 1.2rem;
    text-align: center;
    border: 1px solid var(--color-off-white);
    transition: var(--transition);
    color: var(--color-off-white);
}

.contact__option:hover {
    background:transparent;
    border-color: var(--color-primary-variant);
}

.contact__option-icon {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
}

.contact__option a {
    margin-top: 0.7rem;
    display: inline-block;
    font-size: 0.8rem;
}

#email {
    font-size: 0.66rem;
}

#getin, #contactme {
    color: var(--color-off-white);
}

/* ==== FORM ==== */

form {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}


input, textarea {
    width:100%;
    padding: 1.5rem;
    border-radius: 0.5rem;
    background: transparent;
    border: 1px solid var(--color-off-white);
    resize: none;
    color: var(--color-off-white);
}

.btn {
    border: 1px solid var(--color-off-white);
    background: var(--color-bg-3);
    color: var(--color-off-white);
}

a {
    color: var(--color-off-white);
}

form > h4 {
    color: var(--color-off-white);
    display: flex;
    justify-content: center;
}
/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
    .container.contact__container {
        grid-template-columns: 1fr;
        gap: 2rem;
    }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600px) {
   .container.contact__container {
       width: var(--container-width-sm);
   }

   #email {
       font-size: 0.8rem;
   }
}


