header {
    height: 100vh;
    padding-top: 3rem;
    overflow: hidden;
    background: var(--color-bg);
}

.circle {
    background: var(--color-orange);
    border-radius: 50%;
    width: 100px;
    height: 100px;
}

.header__container {
    text-align: center;
    position: relative;
    height: 100vh;
}

/* ==== CTA styles ==== */
.cta {
    margin-top: 2.5rem;
    display: flex;
    gap: 1.2rem;
    justify-content: center;
}

/* ==== Header Socials styles ==== */

.header__socials {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.8rem;
    position: absolute;
    left: 0;
    bottom: 3rem;
    padding-left: 3rem;
}

.header__socials::after {
    content: '';
    width: 1px;
    height: 2rem;
    background: var(--color-bg-1);
}


 /* ==== Image styles ==== */
 .me {
     width: 22rem;
     height: 29rem;
     position: absolute;
     left: calc(50% - 11rem);
     border-radius: 33rem;
     overflow:hidden;
     padding: 5rem 1.5rem 1.5rem 1.5rem;
     border: 1px solid black;
 }

 #image {
    height:35rem;
    width: 23rem;
    padding-right: 3rem;
}


 /* ==== Scroll down styles ==== */

.scroll__down {
    position: absolute;
    right: -2.3rem;
    bottom: 5rem;
    font-weight: 300;
    font-size: 0.9rem;
    padding-right: 3rem;
}

.title {
    color: var(--color-bg-3);
}

.ctaButton {
    color: var(--color-bg-3);
}

.btn.resume {
    background: transparent;
    border-color: var(--color-bg-3);
    color: var(--color-bg-3);
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
    header {
        height: 100vh;
    }

    .header__container {
        height: 100vh;
    }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600px) {
    header{
        height: 100vh;
        padding-top: 1rem;
    }

    /* don't display socials and scroll down in mobile devices */
    .header__socials,
    .scroll__down {
        display: none;
    }

    .circle {
        width: 50px;
        height: 50px;
        margin-top: 0.5rem;
    }
}