/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville&display=swap');

/* this means every element in the webpage */
* {
    margin: 0;
    padding: 0;
    border: 0;
    outline: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
}

:root {
    /* colors for the app */
    /* --color-bg: #1f1f38; 
    --color-bg-variant: #2c2c6c;
    --color-primary: #4db5ff;
    --color-primary-variant: rgba(77, 181, 255, 0.4);
    --color-white: #fff;
    --color-light: rgba(255, 255, 255, 0.6); */

    --color-bg: #F9F5EC; 
    --color-bg-2: #D8D1BF;
    --color-bg-3: #293924;
    --color-orange: #773C1A;
    --color-bg-variant: #F5F5F5;
    --color-primary: #293924;
    --color-primary-variant: #ABABAB;
    --color-white: #263924;
    --color-light: #263924;
    --color-off-white: #D8D1BF;
    /* --color-white: #ffffff; */

    /* transitions */
    --transition: all 400ms ease;

    /* container class */
    --container-width-lg: 75%;
    --container-width-md: 86%;
    --container-width-sm: 90%;
}

html {
    /* smooth scrolling between the pages */
    scroll-behavior: smooth;
}

::-webkit-scrollbar {
    /* no scrollbar */
    display: none;
}

body {
    /* font-family: 'Poppins', sans-serif; */
    font-family: 'Libre Baskerville',serif;
    background: var(--color-bg);
    /* color: var(--color-white); */
    line-height: 1.7;
}

/* ==== General Styles ==== */
.container {
    width: var(--container-width-lg);
    margin: 0 auto;
}

h1, h2, h3, h4, h5 {
    font-weight: 500;
}

h1 {
    font-size: 2.5rem;
}

/* components are wrapped in section tags */
section {
    /* margin-top: 8rem; */
    padding-top: 4rem;
    padding-bottom: 17rem;
}

section > h2, 
section > h5 {
    text-align: center;
    /* color: var(--color-light); */
}

section > h2 {
    color: var(--color-primary);
    margin-bottom: 3rem;
}

.text-light {
    color: var(--color-light);
}

a {
    /* color: var(--color-primary); */
    transition: var(--transition);
}

a:hover {
    /* color: var(--color-white); */
}

.btn {
    width: max-content;
    display: inline-block;
    /* color: var(--color-bg-3); */
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    /* border: 1px solid var(--color-bg-3); */
    transition: var(--transition);
}

.btn:hover {
    background: var(--color-white);
    color: var(--color-bg);
    border-color: transparent;
}

.btn-clear {
    width: max-content;
    display: inline-block;
    color: var(--color-bg-3);
    padding: 0.75rem 1.2rem;
    border-radius: 0.4rem;
    cursor: pointer;
    border: 1px solid black;
    transition: var(--transition);
}

.btn-clear:hover {
    background: var(--color-orange);
    color: var(--color-bg-2);
    border-color: transparent;
}

img {
    display: block;
    width: 100%;
    object-fit: cover;
}

/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
    .container {
        width: var(--container-width-md);
    }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600px) {
    .container {
        width: var(--container-width-sm);
    }
}

section > h2 {
    margin-top: 1rem;
}



