#portfolio {
    background: var(--color-bg);
}
.portfolio__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2.5rem;
}

.portfolio__item {
    background: var(--color-bg);
    padding: 1.2rem;
    border-top: 1px solid black;
    border-bottom: 1px solid black;
    transition: var(--transition);
}

.portfolio__item:hover {
    border-radius: 2rem;
    border-top: 1px solid black;
}

.portfolio__item-image {
    aspect-ratio: 1/1;
    border-radius: 1.5rem;
    overflow: hidden;
    border: 1px solid black;
}

.portfolio__item h3 {
    display: flex;
    justify-content: center;
    margin: 1.2rem 0 2rem;
}


.portfolio__item-cta {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    justify-content: center;
}

.portfolio__item .openModalBtn {
    background-color: transparent;
}

#portfolio > h5 {
    margin-bottom: 1.5rem;
}



/* ==== Media Queries (Medium Devices) ==== */
@media screen and (max-width: 1024px) {
    .portfolio__container {
        grid-template-columns: 1fr 1fr;
        gap: 1.2rem;
    }
}

/* ==== Media Queries (Small Devices) ==== */
@media screen and (max-width: 600px) {
   .portfolio__container{
       grid-template-columns: 1fr;
       gap: 1rem;
   }

   
}
